import { getAssetUrl } from "@/utils";
import { Box, Button, Container, Stack, Text } from "@mantine/core";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";

export default function GmEsimAppBanner(): JSX.Element {
  const { t } = useTranslation();

  const DownloadButton = ({
    store,
    logo,
    url,
  }: {
    store: string;
    logo: string;
    url: string;
  }) => {
    return (
      <Button
        classNames={{
          root: "h-auto bg-black hover:opacity-80 py-1 w-full md:w-60 rounded-md",
          label: "flex flex-col overflow-visible",
        }}
        leftSection={
          <Image
            src={getAssetUrl(logo)}
            alt="App Store"
            height={24}
            width={24}
          />
        }
        component={Link}
        href={url}
        target="_blank"
      >
        <span className="text-xs">Get it on</span>
        <span>{store}</span>
      </Button>
    );
  };

  return (
    <Box
      className={
        "py-10 md:py-14 relative overflow-hidden mb-12 bg-gradient-to-b " +
        "from-custom-pink-opacity-30 md:bg-none"
      }
    >
      <Container
        size={880}
        className="relative md:px-16 lg:px-32 flex flex-col-reverse md:flex-row"
      >
        <div 
          className={
            "h-[600px] w-1/3 bg-custom-pink-opacity-30 rounded-r-full absolute -z-10 " +
            "left-0 top-1/2 transform -translate-y-1/2 hidden md:block"
          }
        />
        <Stack className="gap-2.5 md:gap-2 w-full md:w-80">
          <Image
            src={getAssetUrl("/services/gm_esim_app/logo.svg")}
            alt="Global Mobile eSIM"
            height={24}
            width={263}
            className="w-full md:w-[263px] h-auto"
          />
          <Text className="text-xl font-bold !text-dark-9">
            {t("top:banners.esim_app.0")}
          </Text>
          <Text className="text-base !text-dark-9">
            {t("top:banners.esim_app.1")}
          </Text>
          <DownloadButton
            store="App Store"
            logo="/apple_logo.svg"
            url="https://apps.apple.com/jp/app/travel-esim-by-global-mobile/id6504782483?l=en-US"
          />
          <DownloadButton
            store="Google Play"
            logo="/google_play_logo.svg"
            url="https://play.google.com/store/apps/details?id=com.inbound_platform.globalmobile&pcampaignid=web_share"
          />
        </Stack>
        <Image
          src={getAssetUrl("/services/gm_esim_app/img.webp")}
          alt="Global Mobile eSIM"
          height={700}
          width={542}
          className={
            "static md:absolute md:right-0 md:transform -md:translate-y-1/2 w-[335px] md:w-[363px] h-auto mx-auto"
          }
          unoptimized
          quality={10}
        />
      </Container>
    </Box>
  );
}
